import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticStyle:{"margin-bottom":"15px","align-items":"center","display":"flex"}},[_vm._m(0),_c('div',{staticClass:"control"},[_c(VSwitch,{model:{value:(_vm.item.styles.show_background_color),callback:function ($$v) {_vm.$set(_vm.item.styles, "show_background_color", $$v)},expression:"item.styles.show_background_color"}})],1)]),(_vm.item.styles.show_background_color)?_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_vm._m(1),_c('div',{staticClass:"control"},[_c(VMenu,{attrs:{"top":"","z-index":"130","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"custom__menu--color",style:(("background-color:" + (_vm.item.styles.background_listing_color)))},on))]}}],null,false,402773520),model:{value:(_vm.show_background_color),callback:function ($$v) {_vm.show_background_color=$$v},expression:"show_background_color"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},model:{value:(_vm.item.styles.background_listing_color),callback:function ($$v) {_vm.$set(_vm.item.styles, "background_listing_color", $$v)},expression:"item.styles.background_listing_color"}})],1)],1)],1)],1)]):_vm._e()]),(_vm.item.styles.show_background_color)?_c('div',[_c('div',{staticStyle:{"margin-bottom":"15px","display":"flex","align-items":"center"}},[_vm._m(2),_c('div',{staticClass:"control"},[_c('vue-dropzone',{ref:"upload",staticStyle:{"margin-bottom":"15px"},attrs:{"id":"upload","options":_vm.dropzoneOptions,"destroyDropzone":false},on:{"vdropzone-mounted":_vm.mountDropzone,"vdropzone-sending":_vm.customEvent,"vdropzone-removed-file":_vm.handleRemove,"vdropzone-success":_vm.handleSuccess}})],1)])]):_vm._e(),_c('PopupCrop',{ref:"PopupCrop",attrs:{"title":"Change image before upload?"},on:{"confirm":_vm.openEditor,"save":_vm.saveImg,"close":_vm.closeCrop}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"label",staticStyle:{"width":"250px"}},[_vm._v("Active Custom Background")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"label",staticStyle:{"width":"250px","margin-bottom":"0"}},[_vm._v(" Background Color ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"label",staticStyle:{"margin-bottom":"0","width":"250px"}},[_vm._v("Background Image")])])}]

export { render, staticRenderFns }